<template>
  <div class="spin">
    <a-spin />
  </div>
</template>

<script>
import { API } from "@/services/api";
import Notification from "@/services/Notification";
export default {
  name: "ActivateUser",
  data() {
    return {
      fetching: true,
    };
  },
  methods: {
    async activeUser() {
      const { replace, currentRoute } = this.$router;
      const { access_token } = this.$route.params;
      const ini = currentRoute.value.path.split("/")[1];
      try {
        const fData = new FormData();
        fData.append('access_token', access_token);
        const res = await API.activeUser(fData, ini);

        const { status, message = "" } = res.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error?.message, {type: "error", title: "Error"});
        }
      } finally {
        replace({path: `/${ini}/login`});
      }
    }
  },
  created() {
    this.activeUser();
  }
}
</script>